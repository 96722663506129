.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
 
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Chrome, Safari, Edge, Opera */
.react-code-input input::-webkit-outer-spin-button,
.react-code-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.react-code-input input[type=number] {
  -moz-appearance: textfield;
}

 
html,
body {
  height: 100%;
  background-color: #333;
  background: url("../../assets/img/bg-pattern.png"), url("../../assets/img/bg.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: none;
  background-size: cover;
}

#responseWrapperView {
  padding:10px;
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}